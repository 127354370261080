import {required, TextInput, TranslatableInputs,} from 'react-admin';
import * as React from 'react';


const CalendarRefForm: React.FC = () => {
    return (
        <div>
            <TranslatableInputs locales={['EN', 'KK', 'RU']} defaultLocale={'RU'}>
                <TextInput
                    source="name"
                    label="Название"
                />
            </TranslatableInputs>
            <TextInput
                source="code"
                name="code"
                label="Код"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
        </div>
    );
};

export default CalendarRefForm;
