import {useEditController, useNotify} from 'react-admin';
import handleClickCustom from "../systemHelpers/CustomHandleClick";
import { useRedirect } from 'react-admin';

interface UseCustomEditControllerProps {
    queue: string;
    type: string;
    resource?: string;
    redirect_to_list?: string;
}

const useCustomEditController = (
    {
        queue,
        type,
        resource = 'account_service',
        redirect_to_list = ''
    }: UseCustomEditControllerProps) => {
    const {record, isLoading} = useEditController({
        resource,
        queryOptions: {
            meta: {
                queue
            }
        }
    });
    const redirect = useRedirect();
    const notify = useNotify(); // Добавляем useNotify

    const handleClick = async (data: any) => {
        try {
            await handleClickCustom(data, resource, queue, type, redirect, record, redirect_to_list);
            notify('Операция выполнена успешно', { type: 'success' });
        } catch (error) {
            notify(`Ошибка выполнения операции: ${error}`, { type: 'error' });
        }
    };

    return {record, isLoading, handleClick};
};

export default useCustomEditController;
