import * as React from "react";
import {
    BooleanField,
    BooleanInput,
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField, TextInput,
    TopToolbar
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="code" label="Код" source="text" defaultValue=""/>,
    <TextInput name="name" label="Название" source="periodTypeId" defaultValue=""/>,
    <BooleanInput name="active" label="Активен" source="periodEnd" defaultValue=""/>,
    <TextInput name="bin" label="БИН" source="bin" defaultValue=""/>,
    <TextInput name="phoneNumber" label="Телефон" source="phoneNumber" defaultValue=""/>,
    <TextInput name="address" label="Адрес" source="address" defaultValue=""/>,
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const organizationRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>

            <TextField source="code" label="Код"/>
            <TextField source="name" label="Название"/>
            <BooleanField source="active" label="Активен"/>
            <TextField source="bin" label="БИН"/>
            <TextField source="phoneNumber" label="Телефон"/>
            <TextField source="address" label="Адрес"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-organization-ref-for-delete"
                type="delete"
                redirect_path="/admin-organizations-ref-list"
            />
        </Datagrid>
    </List>
);


