import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    NumberInput,
    TextField, TextInput,
    TopToolbar,
    FunctionField,
    ChipField
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";
import LanguageIcon from '@mui/icons-material/Language';
import {Avatar} from "@material-ui/core";
// @ts-ignore
import ruFlag from "../../assets/image/ru.png";
// @ts-ignore
import enFlag from "../../assets/image/en.png";
// @ts-ignore
import kzFlag from "../../assets/image/kz.png";



const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="calendarId" label="Календарь" source="calendarId" defaultValue=""/>,
    <TextInput name="periodTypeId" label="Тип периода" source="periodTypeId" defaultValue=""/>,
    <NumberInput name="period" label="Период" source="period" defaultValue=""/>,
    <TextInput name="eventTypeId" label="Тип события" source="eventTypeId" defaultValue=""/>
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const eventRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <FunctionField label="Календарь" render={
                (record: { calendar: any; }) => (
                    <>
                        {record?.calendar?.name?.KK && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} source="calendar.name.KK"/>}
                        {record?.calendar?.name?.RU && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} source="calendar.name.RU"/>}
                        {record?.calendar?.name?.EN && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} source="calendar.name.EN"/>}
                    </>
                )
            } />
            <FunctionField label="Тип периода" render={
                (record: { periodType: any; }) => (
                    <>
                        {record?.periodType?.name?.KK && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} source="periodType.name.KK"/>}
                        {record?.periodType?.name?.RU && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} source="periodType.name.RU"/>}
                        {record?.periodType?.name?.EN && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} source="periodType.name.EN"/>}
                    </>
                )
            } />
            <TextField source="period" label="Период"/>
            <FunctionField label="Тип события" render={
                (record: { eventType: any; }) => (
                    <>
                        {record?.eventType?.name?.KK && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} source="eventType.name.KK"/>}
                        {record?.eventType?.name?.RU && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} source="eventType.name.RU"/>}
                        {record?.eventType?.name?.EN && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} source="eventType.name.EN"/>}
                    </>
                )
            } />
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-event-ref-for-delete"
                type="delete"
                redirect_path="/admin-events-ref-list"
            />
        </Datagrid>
    </List>
);


