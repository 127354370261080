import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField, TextInput,
    TopToolbar,
    FunctionField,
    ChipField
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";
import LanguageIcon from '@mui/icons-material/Language';
import {Avatar} from "@material-ui/core";
// @ts-ignore
import ruFlag from "../../assets/image/ru.png";
// @ts-ignore
import enFlag from "../../assets/image/en.png";
// @ts-ignore
import kzFlag from "../../assets/image/kz.png";


const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="age" label="Возраст" source="age" defaultValue=""/>,
    <TextInput name="genderCode" label="Пол" source="gender.name" defaultValue=""/>,
    <TextInput name="roleCode" label="Роль" source="role.name" defaultValue=""/>,
    <TextInput name="languageCode" label="Язык" source="language.name" defaultValue=""/>
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const adviceRuleRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <TextField source="agesInfo" label="Возраст"/>
            <FunctionField label="Пол" render={
                (record: { gender: any; }) => (
                    <>
                        {record?.gender?.name?.KK && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} source="gender.name.KK"/>}
                        {record?.gender?.name?.RU && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} source="gender.name.RU"/>}
                        {record?.gender?.name?.EN && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} source="gender.name.EN"/>}
                    </>
                )
            } />
            <FunctionField label="Язык" render={
                (record: { language: any; }) => (
                    <>
                        {record?.language?.name?.KK && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} source="language.name.KK"/>}
                        {record?.language?.name?.RU && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} source="language.name.RU"/>}
                        {record?.language?.name?.EN && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} source="language.name.EN"/>}
                    </>
                )
            } />
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-advice-rule-ref-for-delete"
                type="delete"
                redirect_path="/admin-advice-rule-ref-list"
            />
        </Datagrid>
    </List>
);


