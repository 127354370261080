import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField,
    TextInput,
    TopToolbar,
    FunctionField,
    ChipField
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";
import LanguageIcon from '@mui/icons-material/Language';
import {Avatar} from "@material-ui/core";
// @ts-ignore
import ruFlag from "../../assets/image/ru.png";
// @ts-ignore
import enFlag from "../../assets/image/en.png";
// @ts-ignore
import kzFlag from "../../assets/image/kz.png";


const PostListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);

const postFilters = [
    <TextInput name="name" label="Название" source="name" defaultValue=""/>,
    <TextInput name="code" label="Код" source="code" defaultValue=""/>,
];

const services = {'services': 'account_service'}

export const cityRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <FunctionField label="Название" render={
                (record: { name: any; }) => (
                    <>
                        {record?.name?.KK && <ChipField avatar={<Avatar alt="KZ" src={kzFlag} />} source="name.KK"/>}
                        {record?.name?.RU && <ChipField avatar={<Avatar alt="RU" src={ruFlag} />} source="name.RU"/>}
                        {record?.name?.EN && <ChipField avatar={<Avatar alt="EN" src={enFlag} />} source="name.EN"/>}
                    </>
                )
            } />
            <TextField source="code" label="Код"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-city-for-delete"
                type="delete"
                redirect_path="/admin-cities-list"
            />
        </Datagrid>
    </List>
);

