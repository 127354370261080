import {required, TextInput} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const SymptomRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="name"
                name="name"
                label="Название"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="code"
                name="code"
                label="Код"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <CustomReferenceInput
                source="dataTypeId"
                reference="admin-symptom-data-types"
                label="Индификатор Data Type"
                name="dataTypeId"
                filter={{services: 'account_service', resource: "admin-symptoms-data-type-list"}}
                meta={{resource: 'admin-symptom-data-types', model_name: 'SymptomDataTypeRef'}}
            />

        </div>
    );
};

export default SymptomRefForm;
