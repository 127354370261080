//dataProvider.ts
import axios from "axios";
import {DataProvider} from "react-admin";

import authProvider from "../auth/authProvider";

const apiUrl = process.env.REACT_APP_API_URL; // Базовый URL API

const RABBIT_STATUS = {
    'OK': '200',
    'ERROR': '500',
}

const getCookie = (name: string) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    }
    return null;
};

const handleRequest = async (request: () => Promise<any>) => {
    try {
        const response = await request();
        return response;
    } catch (error: any) {
        if (error.response && error.response.status === 401) {
            await authProvider.logout({}); // Используем метод logout
        }
        throw new Error(error.response.message) // Можно кинуть ошибку или вернуть дефолтное значение
    }
};

const getAuthHeaders = () => {
    const token = `${getCookie('access_token')}`;
    if (token) {
        return {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
    }
    return {
        'Content-Type': 'application/json',
    };
};

const customDataProvider: DataProvider = {
    getList: async (resource, params) => {

        const query = {
            ...params,
        }

        const url = `${apiUrl}/${params.filter.services}/${params.filter.resource ? params.filter.resource : resource}`;

        const headers = getAuthHeaders();
        return handleRequest(async () => {
            const response = await axios.post(url, {params: query}, {headers});
            return {
                data: response.data.payload.items,
                total: response.data.payload.total,
            };
        }).catch(() => ({
            data: [],
            total: 0,
        }));

    },

    // Добавьте другие методы в зависимости от ваших потребностей
    getOne: async (resource, params) => {
        let url = `${apiUrl}/${resource}/${params.meta.queue}`;

        const headers = getAuthHeaders();

        return handleRequest(async () => {
            const response = await axios.post(url, {id: params.id}, {headers});

            let sources_with_image = [
                "admin-get-article-ref",
                "admin-get-advice-ref",
                "admin-get-calendar-period-ref",
                "admin-get-service-ref"
            ]

            if (sources_with_image.includes(params.meta.queue)
                && response.data.payload?.image?.["image64"]) {

                const base64String = response.data.payload?.image?.["image64"] ?? '';
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);

                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: "image/jpg"});

                const file = new File([blob], response.data.payload.image["file_name"], {type: "image/jpg"});

                response.data.payload.image = {
                    rawFile: file,
                    src: response.data.payload.image["image"],
                    title: response.data.payload.image["file_name"]
                }
            }
            return {
                data: response.data.payload,
            };
        }).catch(() => ({
            data: {}
        }));
    },

    getMany: async (resource, params: any) => {
        const url = `${apiUrl}/choices/${resource}-choices`;
        const headers = getAuthHeaders();

        const response = await axios.post(
            url,
            {id: params.ids[0], model: params.model},
            {headers});

        return {
            data: response.data.payload,
        };
        // [{id: response.data.payload.id, name: response.data.payload}]
    },

    getManyReference: (resource, params) => {
        return Promise.resolve({data: []});
    },

    update: async (resource, params) => {
        // Реализуйте этот метод, чтобы обновлять элемент
        let url = `${apiUrl}/${resource}/${params.meta.queue}-for-update`;
        const headers = getAuthHeaders();
        return handleRequest(async () => {
            const response = await axios.post(url, {...params.data}, {headers})

            if (response.data.method === RABBIT_STATUS.OK && response.data.payload.id) {
                return {
                    data: response.data.payload,
                };
            } else {
                throw new Error("Invalid response format for 'update'");
            }
        }).catch(() => ({
            data: {}
        }));
    },

    updateMany: (resource, params) => {
        return Promise.resolve({data: []});
    },

    create: async (resource, params) => {
        // Реализуйте этот метод, чтобы создавать новый элемент
        let url = `${apiUrl}/${resource}/${params.data.queue}-for-create`;
        const headers = getAuthHeaders();
        return handleRequest(async () => {
            console.log(url, params.data)
            const response = await axios.post(
                url,
                {...params.data},
                {headers}
            );

            if (response.data.method === RABBIT_STATUS.OK && response.data.payload.id) {
                return {
                    data: response.data.payload,
                };
            } else {
                throw new Error("Invalid response format for 'create'");
            }
        }).catch(() => ({
            data: {}
        }));
    },

    delete: async (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.previousData?.queue}`;
        const headers = getAuthHeaders();
        return handleRequest(async () => {
            console.log({id: params.id})
            const response = await axios.post(url, {id: params.id}, {headers});
            return {
                data: response.data,
            };
        }).catch(() => ({
            data: {}
        }));
    },

    deleteMany: async (resource, params) => {
        const url = `${apiUrl}/${resource}/delete_many`;
        const headers = getAuthHeaders();
        const response = await axios.post(url, params.ids, {headers});

        return {
            data: response.data,
        };
    }


};

const convertFileToBase64 = (file: { rawFile: Blob; }) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });

const customDataProviderWithUploadImage = (customDataProvider: any) => ({
    ...customDataProvider,

    update: (resource: string, params: {
        id: any;
        meta: any;
        data: { image: any; title: any; };
    }) => {

        if (!(["admin-get-article-ref", "admin-get-advice-ref", "admin-get-calendar-period-ref", "admin-get-service-ref"].includes(params.meta.queue)) || !params.data.image) {
            return customDataProvider.update(resource, params);
        }

        // Проверка на null или undefined
        const newPictures: any = params.data.image


        if (newPictures?.rawFile instanceof File) {
            return convertFileToBase64(params.data.image).then(image =>
                customDataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: {
                            src: image,
                            title: params.data.image.title
                        }
                    }
                })
            );
        } else {
            return Promise.reject('Error: Not a file...');
        }
    },

    create: (resource: string, params: {
        meta: any;
        data: { queue: any, image: any; title: any; };
    }) => {
        if (!(["admin-get-article-ref", "admin-get-advice-ref", "admin-get-calendar-period-ref", "admin-get-service-ref"].includes(params.data.queue)) || !params.data.image) {
            return customDataProvider.create(resource, params);
        }

        const newPictures: any = params.data.image;

        if (newPictures.rawFile instanceof File) {
            return convertFileToBase64(params.data.image).then(image =>
                customDataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: {
                            src: image,
                            title: params.data.image.title
                        }
                    }
                })
            );
        } else {
            return Promise.reject('Error: Not a file...');
        }
    },

    getMany: async (resource: any, params: any) => {
        params = {
            ...params,
            "queue": params?.meta?.queue,
            "model": params?.meta?.model,
        };

        return customDataProvider.getMany(resource, params);
    }
});

const uploadCapableDataProvider = customDataProviderWithUploadImage(customDataProvider);

export default uploadCapableDataProvider;


