import * as React from 'react';
import Box from '@mui/material/Box';
import {useState} from 'react';

import {
    MenuItemLink,
    MenuProps,
    useSidebarState,
    DashboardMenuItem
} from 'react-admin';

import Tooltip from '@mui/material/Tooltip';
import EventIcon from '@mui/icons-material/Event';
import SickIcon from '@mui/icons-material/Sick';
import SpaIcon from '@mui/icons-material/Spa';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import MedicalServicesRoundedIcon
    from '@mui/icons-material/MedicalServicesRounded';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CategoryIcon from '@mui/icons-material/Category';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SubMenu from "./SubMenu";

type MenuName =
    'menuAccountService'
    | 'menuSymptomService'
    | 'menuAdviceService'
    | 'menuEventService'
    | 'menuServicesService';

const Menu = ({dense = false}: MenuProps) => {

    const [open] = useSidebarState();
    const [state, setState] = useState({
        menuAccountService: false,
        menuSymptomService: false,
        menuAdviceService: false,
        menuEventService: false,
        menuServicesService: false,
    });

    const handleToggle = (menu: MenuName) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };

    const styleFont = {
        whiteSpace: 'normal', // Разрешить перенос текста
        wordWrap: 'break-word', // Переносить длинные слова
    }

    return (
        <Box
            sx={{
                width: open ? 250 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem
                primaryText='Главная страница'
            />
            <SubMenu
                handleToggle={() => handleToggle('menuAccountService')}
                isOpen={state.menuAccountService}
                name="Пользователи"
                icon={<ManageAccountsIcon/>}
                dense={dense}
            >
                <Tooltip title="Города" arrow>
                    <MenuItemLink
                        to="/admin-cities-list"
                        state={{_scrollToTop: true}}
                        primaryText='Города'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Языки" arrow>
                    <MenuItemLink
                        to="/admin-languages-list"
                        state={{_scrollToTop: true}}
                        primaryText='Языки'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Гендеры" arrow>
                    <MenuItemLink
                        to="/admin-genders-list"
                        state={{_scrollToTop: true}}
                        primaryText='Гендеры'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Роли" arrow>
                    <MenuItemLink
                        to="/admin-roles-list"
                        state={{_scrollToTop: true}}
                        primaryText='Роли'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Медицинские показатели" arrow>
                    <MenuItemLink
                        to="/admin-medical-parameters-list"
                        state={{_scrollToTop: true}}
                        primaryText='Медицинские показатели'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuSymptomService')}
                isOpen={state.menuSymptomService}
                name="Симптомы"
                icon={<SickIcon/>}
                dense={dense}

            >
                <Tooltip title="Типы симптомов" arrow>
                    <MenuItemLink
                        to="/admin-symptoms-data-type-list"
                        state={{_scrollToTop: true}}
                        primaryText="Типы симптомов"
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Справочная инфо симптомов" arrow>
                    <MenuItemLink
                        to="/admin-symptoms-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText="Справочная инфо симптомов"
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Значения симптомов" arrow>
                    <MenuItemLink
                        to="/admin-symptoms-value-list"
                        state={{_scrollToTop: true}}
                        primaryText="Значения симптомов"
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuAdviceService")}
                isOpen={state.menuAdviceService}
                name="Полезные статьи и советы"
                icon={<SpaIcon/>}
                dense={dense}

            >
                <Tooltip title="Правила, отображения полезных статей" arrow>
                    <MenuItemLink
                        to="/admin-articles-rule-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText="Правила, отображения полезных статей"
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Справочник категорий полезных статей" arrow>
                    <MenuItemLink
                        to="/admin-articles-category-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText="Справочник категорий полезных статей"
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Справочник полезных статей" arrow>
                    <MenuItemLink
                        to="/admin-articles-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText="Справочник полезных статей"
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Справочник советов дня" arrow>
                    <MenuItemLink
                        to="/admin-advices-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText="Справочник советов дня"
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Правила, отображения советов дня" arrow>
                    <MenuItemLink
                        to="/admin-advices-rule-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText="Правила, отображения советов дня"
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuEventService')}
                isOpen={state.menuEventService}
                name="События"
                icon={<EventIcon/>}
                dense={dense}
            >
                <Tooltip title="Справочник типов событий" arrow>
                    <MenuItemLink
                        to="/admin-events-type-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник типов событий'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="События, которые происходят" arrow>
                    <MenuItemLink
                        to="/admin-events-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='События, которые происходят'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Справочник календарей" arrow>
                    <MenuItemLink
                        to="/admin-calendars-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник календарей'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Типы периодов событий" arrow>
                    <MenuItemLink
                        to="/admin-periods-type-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Типы периодов событий'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}

                    />
                </Tooltip>
                <Tooltip title="Справочник глобальных событий" arrow>
                    <MenuItemLink
                        to="/admin-calendars-periods-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник глобальных событий'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuServicesService')}
                isOpen={state.menuServicesService}
                name="Услуги"
                icon={<MedicalServicesRoundedIcon/>}
                dense={dense}
            >
                <Tooltip title="Справочник организаций" arrow>
                    <MenuItemLink
                        to="/admin-organizations-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник организаций'
                        leftIcon={<CorporateFareIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Справочник категорий услуг" arrow>
                    <MenuItemLink
                        to="/admin-services-categories-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник категорий услуг'
                        leftIcon={<CategoryIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
                <Tooltip title="Справочник услуг" arrow>
                    <MenuItemLink
                        to="/admin-services-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник услуг'
                        leftIcon={<AutoStoriesIcon/>}
                        dense={dense}
                        sx={styleFont}
                    />
                </Tooltip>
            </SubMenu>
        </Box>
    );
};

export default Menu;
