import {
    BooleanInput,
    required,
    TextInput
} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const ServiceRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="name"
                name="name"
                label="Название"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <BooleanInput
                source="active"
                name="active"
                label="Активен"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="phone"
                name="phone"
                label="Телефон"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="address"
                name="address"
                label="Адрес"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                multiline
                source="text"
                name="text"
                label="Текст"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px', width: '100%'}}
                resettable
            />

            <CustomReferenceInput
                source="organizationId"
                reference="admin-organizations"
                label="Организация"
                name="organizationId"
                filter={{services: 'account_service', resource: "admin-organizations-ref-list"}}
                meta={{resource: 'admin-organizations', model_name: 'OrganizationRef'}}
            />

            <CustomReferenceInput
                source="serviceCategoryId"
                reference="admin-services-categories"
                label="Категория сервиса"
                name="serviceCategoryId"
                filter={{services: 'account_service', resource: "admin-services-categories-ref-list"}}
                meta={{resource: 'admin-services-categories', model_name: 'ServicesCategoriesRef'}}
            />

        </div>
    );
};

export default ServiceRefForm;
