import {Admin, Resource} from "react-admin";
import dataProvider from "../api/providers/data/customDataProvider";

import Layout from "../layout/Layout";
import authProvider from "../api/providers/auth/authProvider";
import {Dashboard} from "../componets/Dashboard";

import {userList} from "../componets/grids/DataGridUser";
import userCreate from "../componets/create/UserCreate";
import userEdit from "../componets/edit/UserEdit";
import UserIcon from "@mui/icons-material/Group";

import {cityRefList} from "../componets/grids/DataGridCityRef";
import cityRefCreate from "../componets/create/CityRefCreate";
import cityRefEdit from "../componets/edit/CityRefEdit";
import LocationCityIcon from '@mui/icons-material/LocationCity';

import {languageRefList} from "../componets/grids/DataGridLanguageRef";
import languageRefCreate from "../componets/create/LanguageRefCreate";
import languageRefEdit from "../componets/edit/LanguageRefEdit";
import LanguageIcon from '@mui/icons-material/Language';

import {genderRefList} from "../componets/grids/DataGridGenderRef";
import genderRefCreate from "../componets/create/GenderRefCreate";
import genderRefEdit from "../componets/edit/GenderRefEdit";
import WcIcon from '@mui/icons-material/Wc';

import {roleRefList} from "../componets/grids/DataGridRoleRef";
import roleRefCreate from "../componets/create/RoleRefCreate";
import roleRefEdit from "../componets/edit/RoleRefEdit";
import Diversity3Icon from '@mui/icons-material/Diversity3';

import {MedicalParametersRefList} from "../componets/grids/DataGridMedicalParameters";
import MedicalParameterRefCreate from "../componets/create/MedicalParameterRefCreate";
import MedicalParametersRefFormEdit from "../componets/edit/MedicalParametersRefEdit";

import {subjectList} from "../componets/grids/DataGridSubject";
import subjectCreate from "../componets/create/SubjectCreate";
import subjectEdit from "../componets/edit/SubjectEdit";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


import {symptomRefList} from "../componets/grids/DataGridSymptomRef";
import symptomRefCreate from "../componets/create/SymptomRefCreate";
import symptomRefEdit from "../componets/edit/SymptomRefEdit";

import {symptomValueList} from "../componets/grids/DataGridSymptomValue";
import symptomValueFormCreate from "../componets/create/SymptomValueCreate";
import symptomValueFormEdit from "../componets/edit/SymptomValueEdit";

import {symptomDataList} from "../componets/grids/DataGridSymptomData";
import symptomDataFormCreate from "../componets/create/SymptomDataCreate";
import symptomDataEdit from "../componets/edit/SymptomDataEdit";

import {symptomDataTypeList} from "../componets/grids/DataGridSymptomDataType";
import symptomDateTypeFormEdit from "../componets/edit/SymptomDateTypeEdit";
import symptomDateTypeFormCreate
    from "../componets/create/SymptomDateTypeCreate";

import {articleRefList} from "../componets/grids/DataGridArticleRef";
import articleRefFormCreate from "../componets/create/ArticleRefCreate";
import articleRefFormEdit from "../componets/edit/ArticleRefEdit";

import {adviceRefList} from "../componets/grids/DataGridAdviceRef";
import adviceRefFormCreate from "../componets/create/AdviceRefCreate";
import adviceRefFormEdit from "../componets/edit/AdviceRefEdit";

import adviceRuleRefCreate from "../componets/create/AdviceRuleRefCreate";
import adviceRuleRefEdit from "../componets/edit/AdviceRuleRefEdit";
import {adviceRuleRefList} from "../componets/grids/DataGridAdviceRuleRef";

import articleRuleRefCreate from "../componets/create/ArticleRuleRefCreate";
import articleRuleRefEdit from "../componets/edit/ArticleRuleRefEdit";
import {articleRuleRefList} from "../componets/grids/DataGridArticleRuleRef";

import eventTypeRefCreate from "../componets/create/EventTypeRefCreate";
import eventTypeRefEdit from "../componets/edit/EventTypeRefEdit";
import {eventTypeRefList} from "../componets/grids/DataGridEventTypeRef";

import eventRefCreate from "../componets/create/EventRefCreate";
import eventRefEdit from "../componets/edit/EventRefEdit";
import {eventRefList} from "../componets/grids/DataGridEventRef";

import calendarPeriodRefCreate
    from "../componets/create/CalendarPeriodRefCreate";
import calendarPeriodRefEdit from "../componets/edit/CalendarPeriodRefEdit";
import {
    calendarPeriodRefList
} from "../componets/grids/DataGridCalendarPeriodRef";

import calendarRefCreate from "../componets/create/CalendarRefCreate";
import calendarRefEdit from "../componets/edit/CalendarRefEdit";
import {calendarRefList} from "../componets/grids/DataGridCalendarRef";

import periodTypeRefEdit from "../componets/edit/PeriodTypeRefEdit";
import periodTypeRefCreate from "../componets/create/PeriodTypeRefCreate";
import {periodTypeRefList} from "../componets/grids/DataGridPeriodTypeRef";


import serviceRefEdit from "../componets/edit/ServiceRefEdit";
import serviceRefCreate from "../componets/create/ServiceRefCreate";
import {serviceRefList} from "../componets/grids/DataGridServiceRef";

import serviceCategoryRefEdit from "../componets/edit/ServiceCategoryRefEdit";
import serviceCategoryRefCreate
    from "../componets/create/ServiceCategoryRefCreate";
import {
    serviceCategoryRefList
} from "../componets/grids/DataGridServiceCateroryRef";

import organizationRefEdit from "../componets/edit/OrganizationRefEdit";
import organizationRefCreate from "../componets/create/OrganizationRefCreate";
import {organizationRefList} from "../componets/grids/DataGridOrganizationRef";
import MedicalParametersRefEdit from "../componets/edit/MedicalParametersRefEdit";


const App = () =>
    (
        <Admin dataProvider={dataProvider}
               basename="Главная страница"
               layout={Layout}
               authProvider={authProvider}
               dashboard={Dashboard}
        >
            <Resource name="users"
                      list={userList}
                      create={userCreate}
                      edit={userEdit}
                      recordRepresentation="name"
                      icon={UserIcon}
            />
            <Resource name="admin-cities-list"
                      list={cityRefList}
                      create={cityRefCreate}
                      edit={cityRefEdit}
                      recordRepresentation="name"
                      icon={LocationCityIcon}
            />
            <Resource name="admin-languages-list"
                      list={languageRefList}
                      create={languageRefCreate}
                      edit={languageRefEdit}
                      recordRepresentation="name"
                      icon={LanguageIcon}
            />
            <Resource name="admin-genders-list"
                      list={genderRefList}
                      create={genderRefCreate}
                      edit={genderRefEdit}
                      recordRepresentation="name"
                      icon={WcIcon}
            />
            <Resource name="admin-roles-list"
                      list={roleRefList}
                      create={roleRefCreate}
                      edit={roleRefEdit}
                      recordRepresentation="name"
                      icon={Diversity3Icon}
            />
            <Resource name="admin-subjects-list"
                      list={subjectList}
                      create={subjectCreate}
                      edit={subjectEdit}
                      recordRepresentation="lastName"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-medical-parameters-list"
                      list={MedicalParametersRefList}
                      create={MedicalParameterRefCreate}
                      edit={MedicalParametersRefEdit}
                      recordRepresentation=""
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-symptoms-data-type-list"
                      list={symptomDataTypeList}
                      create={symptomDateTypeFormCreate}
                      edit={symptomDateTypeFormEdit}
                      recordRepresentation="name"
                      icon={LanguageIcon}
            />
            <Resource name="admin-symptoms-data-list"
                      list={symptomDataList}
                      create={symptomDataFormCreate}
                      edit={symptomDataEdit}
                      recordRepresentation="subjectId"
                      icon={WcIcon}
            />
            <Resource name="admin-symptoms-ref-list"
                      list={symptomRefList}
                      create={symptomRefCreate}
                      edit={symptomRefEdit}
                      recordRepresentation="name"
                      icon={Diversity3Icon}
            />
            <Resource name="admin-symptoms-value-list"
                      list={symptomValueList}
                      create={symptomValueFormCreate}
                      edit={symptomValueFormEdit}
                      recordRepresentation="name"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-articles-ref-list"
                      list={articleRefList}
                      create={articleRefFormCreate}
                      edit={articleRefFormEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-advices-ref-list"
                      list={adviceRefList}
                      create={adviceRefFormCreate}
                      edit={adviceRefFormEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-advices-rule-ref-list"
                      list={adviceRuleRefList}
                      create={adviceRuleRefCreate}
                      edit={adviceRuleRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-articles-rule-ref-list"
                      list={articleRuleRefList}
                      create={articleRuleRefCreate}
                      edit={articleRuleRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-events-type-ref-list"
                      list={eventTypeRefList}
                      create={eventTypeRefCreate}
                      edit={eventTypeRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-events-ref-list"
                      list={eventRefList}
                      create={eventRefCreate}
                      edit={eventRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-calendars-ref-list"
                      list={calendarRefList}
                      create={calendarRefCreate}
                      edit={calendarRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-periods-type-ref-list"
                      list={periodTypeRefList}
                      create={periodTypeRefCreate}
                      edit={periodTypeRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-calendars-periods-ref-list"
                      list={calendarPeriodRefList}
                      create={calendarPeriodRefCreate}
                      edit={calendarPeriodRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-services-categories-ref-list"
                      list={serviceCategoryRefList}
                      create={serviceCategoryRefCreate}
                      edit={serviceCategoryRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-organizations-ref-list"
                      list={organizationRefList}
                      create={organizationRefCreate}
                      edit={organizationRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
            <Resource name="admin-services-ref-list"
                      list={serviceRefList}
                      create={serviceRefCreate}
                      edit={serviceRefEdit}
                      recordRepresentation="code"
                      icon={ManageAccountsIcon}
            />
        </Admin>
    );

export default App;