import {required, TextInput, TranslatableInputs} from 'react-admin';
import * as React from 'react';


const EventTypeRefForm: React.FC = () => {
    return (
        <div>
            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TranslatableInputs locales={['EN', 'KK', 'RU']} defaultLocale={'RU'}>
                <TextInput
                    source="text"
                    label="Текст"
                />
            </TranslatableInputs>
            <TextInput
                source="photoPath"
                name="photoPath"
                label="Изображение"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TranslatableInputs locales={['EN', 'KK', 'RU']} defaultLocale={'RU'}>
                <TextInput
                    source="name"
                    label="Название"
                />
            </TranslatableInputs>
        </div>
    );
};

export default EventTypeRefForm;
