import {
    BooleanInput,
    ImageField,
    ImageInput,
    TextInput,
    TranslatableInputs
} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const ServiceCategoryRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <CustomReferenceInput
                source="parentId"
                reference="admin-services-categories"
                label="Индификатор типа события"
                name="parentId"
                filter={{services: 'account_service', resource: "admin-services-categories-ref-list"}}
                meta={{resource: 'admin-services-categories', model_name: 'ServiceCategoryRef'}}
            />

            <TranslatableInputs locales={['EN', 'KK', 'RU']} defaultLocale={'RU'}>
                <TextInput
                    source="name"
                    label="Название"
                />
            </TranslatableInputs>

            <BooleanInput
                source="active"
                name="active"
                label="Активен"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <ImageInput
                accept="image/*"
                multiple={false}
                source="imagePath"
                name="imagePath"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>


        </div>
    );
};

export default ServiceCategoryRefForm;
