import {
    BooleanInput,
    ImageField,
    ImageInput,
    TextInput
} from 'react-admin';
import * as React from 'react';


const ServiceCategoryRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="parentId"
                name="parentId"
                label="Родитель"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="name"
                name="name"
                label="Название"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <BooleanInput
                source="active"
                name="active"
                label="Активен"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <ImageInput
                accept="image/*"
                multiple={false}
                source="imagePath"
                name="imagePath"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>


        </div>
    );
};

export default ServiceCategoryRefForm;
