import {required, TextInput, TranslatableInputs} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const SymptomRefForm: React.FC = () => {
    return (
        <div>
            <TranslatableInputs locales={['EN', 'KK', 'RU']} defaultLocale={'RU'}>
                <TextInput
                    source="name"
                    label="Название"
                    validate={[required()]}
                />
            </TranslatableInputs>
            <TextInput
                source="code"
                name="code"
                label="Код"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <CustomReferenceInput
                source="dataTypeId"
                reference="admin-symptom-data-types"
                label="Индификатор Data Type"
                name="dataTypeId"
                filter={{
                    services: 'account_service',
                    resource: "admin-symptoms-data-type-list"
                }}
                meta={{
                    resource: 'admin-symptom-data-types',
                    model_name: 'SymptomDataTypeRef'
                }}
                isRequired={true}
            />
            <CustomReferenceInput
                source="calendarId"
                reference="admin-calendars"
                label="Индификатор calendar"
                name="calendarId"
                filter={{
                    services: 'account_service',
                    resource: "admin-calendars-ref-list"
                }}
                meta={{resource: 'admin-calendars', model_name: 'CalendarRef'}}
                multiple={true}
            />
        </div>
    );
};

export default SymptomRefForm;
